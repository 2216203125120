import _isExtendable from "is-extendable";
import _forOwn from "for-own";
var exports = {};
var isObject = _isExtendable;
var forOwn = _forOwn;

exports = function omit(obj, keys) {
  if (!isObject(obj)) return {};
  keys = [].concat.apply([], [].slice.call(arguments, 1));
  var last = keys[keys.length - 1];
  var res = {},
      fn;

  if (typeof last === "function") {
    fn = keys.pop();
  }

  var isFunction = typeof fn === "function";

  if (!keys.length && !isFunction) {
    return obj;
  }

  forOwn(obj, function (value, key) {
    if (keys.indexOf(key) === -1) {
      if (!isFunction) {
        res[key] = value;
      } else if (fn(value, key, obj)) {
        res[key] = value;
      }
    }
  });
  return res;
};

export default exports;